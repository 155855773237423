import { useCallback, useState } from 'react';
import { ErrorK2 } from '../interface';

interface ResponseFormError {
  errors: [{
    field: string | null;
    messageKey: string | null;
    messageParams: string | null;
    rejectedValue: string | null;
  }]
}

function isResponseFormError(responseData: any): responseData is ResponseFormError {
  return 'errors' in responseData && responseData.errors[0].field;
}

export interface FieldInvalid {
  messageKey: string | null;
  messageParams: any | null;
  rejectedValue: string | null;
}

function mapResponseFormErrorToInvalid(err: ResponseFormError): Record<string, FieldInvalid> {
  return err.errors.reduce<Record<string, FieldInvalid>>((prev, error) => {
    if (error.field) {
      return {
        ...prev,
        [error.field]: {
          ...error,
        },
      };
    }
    return prev;
  }, {});
}

export type FetchMethod<T, D> = (
  params: RequestInfo, init?: RequestInit, mapper?: (data: T) => D) => Promise<void>;
export type FetchFieldsError = Record<string, FieldInvalid>;
export type FetchError = ErrorK2 | string | undefined;

export type Hook<T, D> = [
  FetchMethod<T, D>,
  D | undefined,
  boolean,
  FetchError,
  Record<string, FieldInvalid>,
  T | undefined,
];

export function useFetch<T, D>(): Hook<T, D> {
  const [data, setData] = useState<D>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Hook<T, D>[3]>();
  const [fieldsError, setFieldsError] = useState<Hook<T, D>[4]>({});
  const [allData, setAllData] = useState<Hook<T, D>[5]>();

  const request = useCallback<Hook<T, D>[0]>(
    async (params, init, mapper) => {
      setLoading(true);
      setError(undefined);
      setFieldsError({});
      let contentTypeHeaders: Record<string, string> = { 'Content-Type': 'application/json' };
      const restHeaders = init?.headers;
      // reset Content-Type by setting undefined
      if (restHeaders && 'Content-Type' in restHeaders && restHeaders['Content-Type'] === 'undefined') {
        contentTypeHeaders = {};
        delete restHeaders['Content-Type'];
      }

      try {
        const response = await fetch(params, init ? {
          ...init,
          headers: {
            ...contentTypeHeaders,
            ...init?.headers,
          },
        } : undefined);
        const responseData = (response.headers.get('Content-Type') === 'application/json' ? await response.json() : await response.text()) as T;

        if (response.status === 200) {
          setData(mapper ? mapper(responseData) : responseData as unknown as D);
          setAllData(responseData);
        } else if (isResponseFormError(responseData)) {
          setFieldsError(mapResponseFormErrorToInvalid(responseData));
        } else {
          setError(responseData as any);
        }
      } catch (e) {
        setError('unknown error');
      }

      setLoading(false);
    }, [],
  );

  return [
    request,
    data,
    loading,
    error,
    fieldsError,
    allData,
  ];
}
