import { Logo } from '@nnpl/ui-library/esm/Logo';
import { AnimatedLoader } from '@nnpl/ui-library/esm/AnimatedLoader';
import styled from 'styled-components';
import { IconSize } from '@nnpl/ui-library/esm/Icon';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderWrapper = styled.div`
  position: absolute;
`;

export const FullPageLoader: React.FC = () => (
  <Wrapper>
    <LoaderWrapper>
      <AnimatedLoader size={IconSize.xl} />
    </LoaderWrapper>
    <Logo variant="basic" />
  </Wrapper>
);
