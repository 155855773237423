import { ToastVariant } from '@nnpl/ui-library/esm/Toast';
import { FormEvent, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { apiPrefix } from '../../../api';
import { useFetch } from '../../../hooks/useFetch';
import { useForm } from '../../../hooks/useForm';
import { useToastK2 } from '../../../hooks/useToastK2';

interface Hook {
  handleSubmit: (e:FormEvent) => void;
  isLoading: boolean;
  values: {
    pesel: string;
  };
  data?: any;
  onChangeFieldValue: (fieldName: 'pesel', fieldValue: unknown) => void;
}

export function usePasswordResetPESEL(): Hook {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { values, onChangeFieldValue } = useForm({ pesel: '' });

  const { playToast } = useToastK2();

  // submit
  const [formSubmit, data, isLoading, formError] = useFetch<{ email: string }, string>();

  const handleSubmit = useCallback<Hook['handleSubmit']>(async (e: FormEvent) => {
    e.preventDefault();

    if (executeRecaptcha) {
      const recaptchaResponse = await executeRecaptcha();
      await formSubmit(
        `${apiPrefix}/onboarding-auth/public/reset/`,
        {
          method: 'POST',
          redirect: 'error',
          body: JSON.stringify({
            pesel: values.pesel,
            recaptchaResponse,
          }),
        },
      );
    }
  }, [formSubmit, values, executeRecaptcha]);

  useEffect(() => {
    if (formError) {
      playToast({
        containerId: 'login',
        variant: ToastVariant.ERROR,
        message: 'Podany PESEL i/lub hasło są nieprawidłowe. Popraw wprowadzone dane i spróbuj ponownie.',
      });
    }
  }, [formError, playToast]);

  return {
    values: {
      pesel: `${values.pesel}`,
    },
    data,
    isLoading,
    handleSubmit,
    onChangeFieldValue,
  };
}
