import { Link } from '@nnpl/ui-library/esm/Link';
import { MouseEvent, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  to: string;
  className?: string;
  children: ReactNode;
}

export const NaviLink:React.FC<Props> = ({
  to, className, children,
}) => {
  const history = useHistory();

  const handleOnClick = (e: MouseEvent) => {
    e.preventDefault();
    history.push(to);
  };

  return (
    <Link // eslint-disable-line
      className={className}
      href="#"
      onClick={handleOnClick}
    >
      {children}
    </Link>
  );
};
