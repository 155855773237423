import { color } from '@nnpl/ui-library';
import styled from 'styled-components';
import { ToastContainer, ToastVariant } from '@nnpl/ui-library/esm/Toast';
import { useEffect } from 'react';
import { PaddingsWrapper } from '../ui/Margins/Margins';
import { ActivateAccountForm } from './components/ActivateAccountForm/ActivateAccountForm';
import backgroundUrl from '../../background.jpg';
import { Footer } from '../ui/Footer/Footer';
import { Header } from '../ui/Header/Header';
import { MainContent } from '../ui/MainCotnent/MainContent';
import { ActivateError } from '../ActivateError/ActivateError';
import { useActivationForm } from './useActivationSubmit';
import translations from './translations.json';
import { addTranslations } from '../../i18n';
import { ActivateSuccess } from './components/ActivateSuccess/ActivateSuccess';
import { useToastK2 } from '../../hooks/useToastK2';
import { AboutTypo, AboutWrapper, AboutTypoRow } from '../ui/About/AboutTypo';

addTranslations(translations);

const AboutTypoRow1 = styled(AboutTypoRow)`
  width: 62%;
  min-width: 380px;
`;

const AboutTypoRow2 = styled(AboutTypoRow)`
  width: 62%;
  min-width: 490px;
`;

const AboutTypoRow3 = styled(AboutTypoRow)`
  width: 90%;
  min-width: 380px;
  max-width: 1100px
`;

const AboutTypoRow4 = styled(AboutTypoRow)`
  width: 95%;
  min-width: 500px;
  max-width: 1200px
`;

const LeftColumn = styled(PaddingsWrapper.withComponent('form'))`
  display: flex;
  flex-direction: column;
  width: 605px;
  background: ${color.primary.white};
  padding-top: 100px;
`;

const RightColumn = styled.div`
  flex: 1;
  background: url(${backgroundUrl});
  background-size: cover;
  position: relative;
`;

const notMatchText = `
  Niestety nie udało się aktywować Twojego konta.
  Upewnij się, że podane w formularzu informacje są poprawne i zgodne z przekazanymi w dokumentach rekrutacyjnych.
  Jeśli dane są poprawne skontaktuj się ze swoim przełożonym.
`;

interface Props {
  token: string;
}

export const ActivateAccount: React.FC<Props> = ({ token }) => {
  const { playToast } = useToastK2({ containerId: 'activate' });

  const {
    values, onChangeFieldValue, handleSubmit, formFieldsError, formLoading, formData, formError,
  } = useActivationForm(token);

  useEffect(() => {
    if (formFieldsError.pesel?.messageKey === 'PESEL-NOT-MATCH'
     || formFieldsError.phone?.messageKey === 'PHONE-NOT-MATCH') {
      playToast({
        variant: ToastVariant.ERROR,
        message: notMatchText,
      });
    }
  }, [formFieldsError, playToast]);

  if (formError) {
    return (
      <ActivateError error={formError} />
    );
  }

  if (formData) {
    return (
      <ActivateSuccess firstName={formData.firstName} />
    );
  }

  return (
    <>
      <Header />
      <MainContent>
        <LeftColumn
          $paddingBottom="XL"
          $paddingsVertical="XL"
          onSubmit={handleSubmit}
        >
          <ActivateAccountForm
            formFieldsError={formFieldsError}
            formLoading={formLoading}
            onChangeFieldValue={onChangeFieldValue}
            values={values}
          />
        </LeftColumn>
        <RightColumn>
          <AboutWrapper>
            <AboutTypoRow1>
              <AboutTypo $color="orangeMedium" $type="H1">Jesteś tu, ponieważ</AboutTypo>
            </AboutTypoRow1>
            <AboutTypoRow2>
              <AboutTypo $color="orangeMedium" $type="H1">zaczynasz nową przygodę.</AboutTypo>
            </AboutTypoRow2>
            <AboutTypoRow3>
              <AboutTypo $color="orangeDark" $type="H1">Wypełnij formularz,</AboutTypo>
            </AboutTypoRow3>
            <AboutTypoRow4>
              <AboutTypo $color="orangeDark" $type="H1">aktywuj konto i startujemy!</AboutTypo>
            </AboutTypoRow4>
          </AboutWrapper>
        </RightColumn>
        <ToastContainer containerId="activate" />
      </MainContent>
      <Footer />
    </>
  );
};
