import styled from 'styled-components';
import { ButtonTo } from '../ui/ButtonTo/ButtonTo';
import { Footer } from '../ui/Footer/Footer';
import { Header } from '../ui/Header/Header';
import { MainContent } from '../ui/MainCotnent/MainContent';
import { PaddingsWrapper } from '../ui/Margins/Margins';
import { Spacer } from '../ui/Margins/Spacer';
import { Typo } from '../ui/Typography/Typography';
import roadSrc from './road.png';
import dogSrc from './dog.png';

const RightColumn = styled(PaddingsWrapper.withComponent('div'))`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-right: 72px;
`;

const TextWrapper = styled.div`
  max-width: 545px;
`;

const errorTexts = {
  'ACCOUNT-NOT-ACTIVE': (
    <>
      <Typo $color="orangeMedium" $type="H1">Twoje konto zostało zablokowane</Typo>
      <Typo $type="H5">
        Przykro nam, ale Twoje konto zostało zablokowane ponieważ
        przekroczono dopuszczalną ilość prób logowania.
        <br />
        Robimy tak, ponieważ zależy nam na tym aby konta naszych użytkowników były bezpieczne.
      </Typo>
      <Spacer $size="S" />
      <Typo $type="H5">
        Aby odblokować konto skorzystaj z procesu odzyskiwania hasła za pomocą poniższego przycisku.
      </Typo>
      <Spacer $size="S" />
      <Typo $type="H5">
        Jeśli Twój problem jest innego typu skontaktuj się ze swoim menedżerem.
      </Typo>
      <Spacer $size="XL" />
      <div>
        <ButtonTo to="/password-reset">Odzyskiwanie hasła</ButtonTo>
      </div>
    </>
  ),
  'ACCOUNT-IS-JOINED': (
    <>
      <Typo $color="orangeMedium" $type="H1">Nie tędy droga...</Typo>
      <Typo $type="H5">
        Ponieważ jesteś już Doradcą zaloguj się bezpośrednio do aplikacji K2.
        <br />
        W menu po lewej stronie znajdziesz przekierowanie do sekcji Mój Start.
      </Typo>
      <Spacer $size="S" />
      <Typo $type="H5">
        Zapraszamy!
      </Typo>
      <Spacer $size="XL" />
      <div>
        <ButtonTo to="https://k2.nn.pl/">Przejdź do K2</ButtonTo>
      </div>
    </>
  ),
};

const errorImages = {
  'ACCOUNT-NOT-ACTIVE': { alt: 'dog', src: dogSrc },
  'ACCOUNT-IS-JOINED': { alt: 'road', src: roadSrc },
};

export function isKeyOfErrorTexts(key?: string): key is keyof typeof errorTexts {
  if (!key) {
    return false;
  }
  return Object.keys(errorTexts).includes(key);
}

interface Props {
  error: keyof typeof errorTexts;
}

export const LoginError:React.FC<Props> = ({ error }) => (
  <>
    <Header />
    <MainContent>
      <LeftColumn>
        <img alt={errorImages[error].alt} src={errorImages[error].src} />
      </LeftColumn>
      <RightColumn $paddingsHorizontal="XL">
        <TextWrapper>
          {errorTexts[error]}
        </TextWrapper>
      </RightColumn>
    </MainContent>
    <Footer />
  </>
);
