import { ToastVariant } from '@nnpl/ui-library/esm/Toast';
import { FormEvent, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { apiPrefix } from '../../api';
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';
import { useToastK2 } from '../../hooks/useToastK2';

interface Hook {
  handleSubmit: (e:FormEvent) => void;
  isLoading: boolean;
  values: {
    login: string;
    password: string;
  };
  onChangeFieldValue: (fieldName: 'login' | 'password', fieldValue: unknown) => void;
  loginErrorCode?: string;
}

export function useLogin(): Hook {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { values, onChangeFieldValue } = useForm({
    login: '',
    password: '',
  });

  const { playToast } = useToastK2();

  // submit
  const [formSubmit, successData, isLoading, formError] = useFetch<unknown, any>();

  const handleSubmit = useCallback<Hook['handleSubmit']>(async (e: FormEvent) => {
    e.preventDefault();
    if (executeRecaptcha) {
      const recaptchaResponse = await executeRecaptcha();
      await formSubmit(
        `${apiPrefix}/onboarding-auth/login`,
        {
          method: 'POST',
          credentials: 'include',
          redirect: 'error',
          body: JSON.stringify({
            login: values.login,
            password: values.password,
            recaptchaResponse,
          }),
        },
      );
    }
  }, [formSubmit, values, executeRecaptcha]);

  useEffect(() => {
    // only if empty error object
    if (typeof formError === 'object' && !('errorCode' in formError)) {
      playToast({
        containerId: 'login',
        variant: ToastVariant.ERROR,
        message: 'Podany PESEL i/lub hasło są nieprawidłowe. Popraw wprowadzone dane i spróbuj ponownie.',
      });
    }
  }, [formError, playToast]);

  useEffect(() => {
    if (successData !== undefined) {
      window.location.replace(apiPrefix);
    }
  }, [successData]);

  return {
    values,
    isLoading,
    handleSubmit,
    onChangeFieldValue,
    loginErrorCode: typeof formError === 'object' && 'errorCode' in formError ? (formError as any).errorCode : undefined,
  };
}
