import { Button } from '@nnpl/ui-library/esm/Button';
import { useHistory } from 'react-router-dom';

interface Props {
  variant?: 'primary' | 'secondary' | 'tertiary';
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  disabled?: boolean;

  to: string;
}

export const ButtonTo: React.FC<Props> = ({ to, children, ...props }) => {
  const history = useHistory();
  let onClickHandler;
  if (to.startsWith('https')) {
    onClickHandler = () => window.location.replace(to);
  } else {
    onClickHandler = () => history.push(to);
  }

  return (
    <Button
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      onClick={onClickHandler}
    >
      {children}
    </Button>
  );
};
