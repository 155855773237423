import { color } from '@nnpl/ui-library';
import styled, { css, CSSProp } from 'styled-components';
import './fonts/fonts.css';

interface Props {
  $type: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6' | 'body' | 'caption' | 'overline';
  $noMargin?: boolean;
  $inline?: boolean;
  $color?: keyof typeof color.primary;
}

function getTypoStyle({ $type }: Props): CSSProp {
  switch ($type) {
    case 'H1':
      return css`
        font-size: 2.5rem;
        font-family: "NNDagnyDisplay", sans-serif;
        line-height: 2.75rem;
        margin-bottom: 1.5rem;
        font-weight: normal;
      `;
    case 'H2':
      return css`
          font-size: 2rem;
          font-family: "NNDagnyDisplay", sans-serif;
          line-height: 2.5rem;
          margin-bottom: 1.5rem;
          font-weight: normal;
        `;
    case 'H3':
      return css`
          font-size: 1.75rem;
          font-family: "NNDagnyDisplay", sans-serif;
          line-height: 2.25rem;
          margin-bottom: 1rem;
          font-weight: normal;
        `;
    case 'H4':
      return css`
          font-size: 1.25rem;
          font-family: "NNDagnyDisplay", sans-serif;
          line-height: 1.75rem;
          margin-bottom: 1rem;
          font-weight: normal;
        `;
    case 'H5':
      return css`
          font-size: 1.25rem;
          font-family: "NNDagnytext", sans-serif;
          line-height: 1.75rem;
          margin-bottom: 0.5rem;
          font-weight: normal;
        `;
    case 'H6':
      return css`
          font-size: 1rem;
          font-family: "NNDagnyText", sans-serif;
          line-height: 1.25rem;
          margin-bottom: 0.5rem;
          font-weight: normal;
        `;
    case 'caption':
      return css`
          font-size: 0.75rem;
          font-family: "NNDagnyText", sans-serif;
          line-height: 1rem;
          margin-bottom: 0;
          font-weight: normal;
        `;
    case 'overline':
      return css`
          font-size: 0.623rem;
          font-family: "NNDagnyText", sans-serif;
          line-height: 0.75rem;
          margin-bottom: 0;
          font-weight: normal;
          text-transform: uppercase;
        `;
    default:
      return css`
          font-size: 1rem;
          font-family: "NNDagnyDisplay", sans-serif;
          line-height: 1.25rem;
          margin-bottom: 1rem;
          font-weight: normal;
        `;
  }
}

export const Typo = styled.p<Props>`
  margin: 0;
  color: ${color.primary.greyDark};
  ${getTypoStyle}
  ${({ $noMargin }) => ($noMargin ? css`margin: 0;` : '')}
  ${({ $inline }) => ($inline ? css`display: inline;` : '')}
  ${({ $color }) => ($color ? css`color: ${color.primary[$color]};` : '')}

  ul {
    list-style: none;
  }

  li::before {
    content: ' ';
    background: ${color.primary.orangeDark};
    border-radius: 50%;
    height: 0.5em;
    font-weight: bold;
    margin-left: -1.5em;
    margin-right: 1em;
    display: inline-block;
    width: 0.5em;
  }

  li {
    margin-bottom: 12px;
  }
`;

export const BodyTypo = Typo;
