import { color } from '@nnpl/ui-library';
import styled from 'styled-components';
import { ButtonTo } from '../../../ui/ButtonTo/ButtonTo';
import { Footer } from '../../../ui/Footer/Footer';
import { Header } from '../../../ui/Header/Header';
import { MainContent } from '../../../ui/MainCotnent/MainContent';
import { PaddingsWrapper } from '../../../ui/Margins/Margins';
import { Spacer } from '../../../ui/Margins/Spacer';
import { Typo } from '../../../ui/Typography/Typography';
import bgSrc from './bg.png';
import markSrc from './mark.png';

const RightColumn = styled(PaddingsWrapper.withComponent('div'))`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

const LeftColumn = styled.div`
  width: 366px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background: ${color.primary.greyWhite};
  overflow: hidden;

  & > img {
    margin-right: -60px;
  }
`;

const TextWrapper = styled.div`
  max-width: 545px;
`;

const Mark = styled.div`
  margin: 30px 0;
  text-align: center;
`;

interface Props {
  firstName: string;
}

export const ActivateSuccess:React.FC<Props> = ({ firstName }) => (
  <>
    <Header />
    <MainContent>
      <LeftColumn>
        <img alt="bg" src={bgSrc} />
      </LeftColumn>
      <RightColumn $paddingsHorizontal="XL">
        <TextWrapper>
          <Mark>
            <img alt="OK" src={markSrc} />
          </Mark>
          <Typo $color="orangeMedium" $type="H2">
            {firstName}
            , dziękujemy za rejestrację konta
          </Typo>
          <Typo $type="H5">
            Twoje konto jest już aktywne.
            <br />
            Strefa
            <Typo $inline $noMargin $type="H4"> Mój Start </Typo>
            pomoże ci w procesie szkoleniowym oraz
            przygotowaniu do pracy Doradcy w Nationale Nederlanden.
          </Typo>
          <Spacer $size="S" />
          <Typo $type="H5">
            Za pomocą poniższego przycisku przejdź do strony
            logowania i zaloguj się do swojej strefy szkoleniowej.
          </Typo>
          <Spacer $size="XL" />
          <div>
            <ButtonTo to="/">Zaloguj się</ButtonTo>
          </div>
        </TextWrapper>
      </RightColumn>
    </MainContent>
    <Footer />
  </>
);
