import { PasswordInput } from '@k2nn/k2-ui-library';
import { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { FieldInvalid } from '../../../hooks/useFetch';
import { Typo } from '../Typography/Typography';
import { PasswordProgressBar } from './PasswordProgressBar';

const Wrapper = styled.div`
  position: relative;
`;

const PasswordQuality = styled(Typo)`
  position: absolute;
  right: 0;
  bottom: 16px;
`;

interface Props extends React.ComponentProps<typeof PasswordInput> {
  fieldError?: FieldInvalid;
  hideRequired?: boolean;
  hideFeedback?: boolean;
  hideDescription?: boolean;
}

function getProgressInfo(value?: string | number | readonly string[]): {
  progress: number, feedbackInfo: string;
} {
  let progress = 0;
  const feedbackInfoArr = [];

  if (`${value}`.length >= 8) {
    progress += 1;
  } else {
    feedbackInfoArr.push('minimum 8 znaków');
  }

  if (/[A-Z]/.test(`${value}`)) {
    progress += 1;
  } else {
    feedbackInfoArr.push('1 wielkiej litery');
  }

  if (/[a-z]/.test(`${value}`)) {
    progress += 1;
  } else {
    feedbackInfoArr.push('1 małej litery');
  }

  if (/\d/.test(`${value}`)) {
    progress += 1;
  } else {
    feedbackInfoArr.push('1 cyfry');
  }

  if (/[!@#$%^&*()+=[\]{};'\\:|",./<>?~`]/.test(`${value}`)) {
    progress += 1;
  } else {
    feedbackInfoArr.push('1 znaku specjalnego');
  }

  let feedbackInfo = '';

  if (feedbackInfoArr.length === 0) {
    feedbackInfo = 'Twoje hasło jest zgodne z naszymi rekomendacjami';
  } else {
    feedbackInfo = ['Użyj: ', feedbackInfoArr.join(', ')].join('');
  }

  return {
    progress,
    feedbackInfo,
  };
}

function getPasswordQualityText(progress: number): string {
  if (progress === 5) {
    return 'Bardzo silne';
  }

  return progress > 2 ? 'Średnie' : 'Bardzo słabe';
}

export const InputPassword: React.FC<Props> = ({
  fieldError, value, hideFeedback, hideDescription, hideRequired, required, ...props
}) => {
  const [blurred, setBlurred] = useState(false);
  let localFieldError: string | undefined;
  const { progress, feedbackInfo } = getProgressInfo(value);

  if (blurred) {
    if (hideFeedback !== true && progress !== 5) {
      localFieldError = feedbackInfo;
    }

    if (!hideRequired && required && `${value}`.length === 0) {
      localFieldError = 'Uzupełnij to pole';
    }
  }

  const handleBlur = useCallback(() => {
    setBlurred(true);
  }, []);

  const error = (localFieldError || fieldError?.messageKey) || undefined;

  return (
    <Wrapper>
      <PasswordInput
        caption={(!error && !hideFeedback) ? feedbackInfo : undefined}
        error={error}
        onBlur={handleBlur}
        required={required}
        value={value}
        {...props} // eslint-disable-line
      />
      {
        hideFeedback !== true && (
        <>
          {typeof value === 'string' && value.length > 0 && <PasswordQuality $color="greyMedium" $type="caption">{getPasswordQualityText(progress)}</PasswordQuality>}
          <PasswordProgressBar progress={progress} />
        </>
        )
      }
    </Wrapper>
  );
};
