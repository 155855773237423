import { useEffect } from 'react';
import { apiPrefix } from '../api';
import { useFetch } from './useFetch';

export function useInitCaptcha(): string | undefined {
  const [fetchCaptchaKey, captchaKey] = useFetch<{ recaptchaSitekey:string }, string>();

  useEffect(() => {
    fetchCaptchaKey(`${apiPrefix}/onboarding-auth/public`, undefined, (d) => d.recaptchaSitekey);
  }, [fetchCaptchaKey]);

  return captchaKey;
}
