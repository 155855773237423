import { Input } from '@k2nn/k2-ui-library';
import { useCallback, useState } from 'react';
import { FieldInvalid } from '../../../hooks/useFetch';

interface Props extends React.ComponentProps<typeof Input> {
  fieldError?: FieldInvalid;
  prefix?: string;
}

export const InputPhone: React.FC<Props> = ({
  fieldError, value, error, prefix, ...props
}) => {
  const [blurred, setBlurred] = useState(false);
  let localFieldError: string | undefined;

  if (blurred) {
    if (`${value}`.length === 0) {
      localFieldError = 'Uzupełnij to pole';
    } else if (`${value}`.length < 9) {
      localFieldError = 'Wpisz poprawny numer telefonu. Właściwy format zawiera 9 cyfr.';
    }
  }

  const handleBlur = useCallback(() => {
    setBlurred(true);
  }, []);

  return (
    <Input
      {...props} // eslint-disable-line
      error={localFieldError || error}
      keyPattern="[0-9]"
      maxLength={9}
      onBlur={handleBlur}
      pattern="[0-9]{6,9}"
      prefix={prefix}
      type="tel"
      value={value}
    />
  );
};
