import { FormEvent, useCallback } from 'react';
import { apiPrefix } from '../../api';
import { FetchError, FetchFieldsError, useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';

const formInitValues = {
  phone: '', pesel: '', password: '', passwordRepeat: '',
} as const;

export interface Hook {
  values: {
    phone: string;
    pesel: string;
    password: string;
    passwordRepeat: string;
  },
  onChangeFieldValue: (fieldName: keyof typeof formInitValues, fieldValue: unknown) => void;
  handleSubmit: (e: FormEvent) => void;
  formFieldsError: FetchFieldsError;
  formLoading: boolean;
  formData?: { firstName: string };
  formError: FetchError;
}

export function useActivationForm(token: string): Hook {
  const { values, onChangeFieldValue } = useForm(formInitValues);

  // submit
  const [formSubmit, formData, formLoading, formError, formFieldsError] = useFetch<unknown, any>();

  const handleSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    if (values.password === values.passwordRepeat) {
      formSubmit(
        `${apiPrefix}/onboarding-auth/public/register/`,
        {
          method: 'POST',
          body: JSON.stringify({
            phone: values.phone.replaceAll(' ', '').replace('+48', ''),
            pesel: values.pesel,
            password: values.password,
            token,
          }),
        },
      );
    }
  }, [formSubmit, values, token]);

  return {
    values,
    onChangeFieldValue,
    handleSubmit,
    formFieldsError,
    formLoading,
    formData,
    formError,
  };
}
