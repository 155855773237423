import { Logo } from '@nnpl/ui-library/esm/Logo';
import styled from 'styled-components';
import { PaddingsWrapper } from '../Margins/Margins';

const HeaderWrapper = styled(PaddingsWrapper.withComponent('div'))`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const Header: React.FC = () => (
  <HeaderWrapper $paddingLeft="XL" $paddingTop="M">
    <Logo variant="full" />
  </HeaderWrapper>
);
