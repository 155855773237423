import { useParams } from 'react-router-dom';
import { ActivateAccount } from '../ActivateAccount/ActivateAccount';
import { ActivateError } from '../ActivateError/ActivateError';
import { PasswordResetNew } from '../PasswordReset/SetNew/PasswordResetNew';
import { FullPageLoader } from '../ui/FullPageLoader/FullPageLoader';
import { useToken } from './useToken';

export const TokenValidator: React.FC = () => {
  const { token } = useParams<{ token: string }>();

  const { tokenLoading, isReset, tokenError } = useToken(token);

  if (tokenLoading) {
    return <FullPageLoader />;
  }

  if (tokenError) {
    return (
      <ActivateError error={tokenError} isReset={isReset} />
    );
  }

  if (isReset) {
    return <PasswordResetNew token={token} />;
  }

  return <ActivateAccount token={token} />;
};
