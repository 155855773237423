import React, { Suspense } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from '@nnpl/ui-library/esm/Toast';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { FullPageLoader } from './components/ui/FullPageLoader/FullPageLoader';
import { Login } from './components/Login/Login';
import { PasswordResetPESEL } from './components/PasswordReset/PESEL/PasswordResetPESEL';
import { TokenValidator } from './components/TokenValidator/TokenValidator';
import { useInitCaptcha } from './hooks/useInitCaptcha';

const AppWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const GlobalStyle = createGlobalStyle`
  .grecaptcha-badge { visibility: hidden; };
`;

export const App: React.FC = () => {
  const captchaKey = useInitCaptcha();

  if (!captchaKey) {
    return <FullPageLoader />;
  }

  return (
    <AppWrapper>
      <GlobalStyle />
      <GoogleReCaptchaProvider
        reCaptchaKey={captchaKey}
        scriptProps={{ async: true, defer: true, appendTo: 'body' }}
      >
        <Suspense fallback={<FullPageLoader />}>
          <Switch>
            <Route exact path="/password-reset">
              <PasswordResetPESEL />
            </Route>
            <Route path="/:token">
              <TokenValidator />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </Suspense>
        <ToastContainer containerId="global" />
      </GoogleReCaptchaProvider>
    </AppWrapper>
  );
};
