import { UseToastProps } from '@nnpl/ui-library/dist/components/Toast/hooks/useToast.hook';
import { useToast } from '@nnpl/ui-library/esm/Toast';
import { useCallback } from 'react';

export function useToastK2(config?: Omit<UseToastProps, 'message'>): ReturnType<typeof useToast> {
  const useToastReturn = useToast({
    message: 'x',
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    autoClose: 3000,
    ...config,
  });

  // do not include playtoast as deps - it's broken
  const playToast = useCallback<typeof useToastReturn.playToast>(useToastReturn.playToast, []);

  return {
    ...useToastReturn,
    playToast,
  };
}
