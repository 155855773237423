import { nnColors } from '@k2nn/k2-ui-library';
import { Button } from '@nnpl/ui-library/esm/Button';
import { ToastContainer } from '@nnpl/ui-library/esm/Toast';
import styled from 'styled-components';
import { AboutWrapper, AboutTypo, AboutTypoRow } from '../../ui/About/AboutTypo';
import { Footer } from '../../ui/Footer/Footer';
import { InputPESEL } from '../../ui/Form/InputPESEL';
import { GoogleInfo } from '../../ui/GoogleInfo';
import { Header } from '../../ui/Header/Header';
import { MainContent } from '../../ui/MainCotnent/MainContent';
import { PaddingsWrapper } from '../../ui/Margins/Margins';
import { Spacer } from '../../ui/Margins/Spacer';
import { Typo } from '../../ui/Typography/Typography';
import backgroundUrl from '../background.jpg';
import { PasswordResetPESELSuccess } from './PasswordResetPESELSuccess';
import { usePasswordResetPESEL } from './usePasswordResetPESEL';

const AboutTypoRow1 = styled(AboutTypoRow)`
  width: 50%;
  min-width: 450px;
`;

const AboutTypoRow2 = styled(AboutTypoRow)`
  width: 94%;
  min-width: 450px;
  max-width: 1000px;
  color: ${nnColors.orangeDark};
`;

const LeftColumn = styled(PaddingsWrapper.withComponent('form'))`
  display: flex;
  flex-direction: column;
  width: 605px;
  background: ${nnColors.white};
  padding-top: 100px;
  justify-content: center;
`;

const RightColumn = styled.div`
  flex: 1;
  background: url(${backgroundUrl});
  background-size: cover;
  position: relative;
  transform: scaleX(-1);
`;

const RevertTransform = styled.div`
  transform: scaleX(-1);
  flex: 1;
  width: 100%;
  height: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PasswordResetPESEL: React.FC = () => {
  const {
    handleSubmit, data, isLoading, values, onChangeFieldValue,
  } = usePasswordResetPESEL();

  if (data) {
    return (<PasswordResetPESELSuccess />);
  }

  return (
    <>
      <Header />
      <MainContent>
        <LeftColumn
          $paddingBottom="XL"
          $paddingsVertical="XL"
          onSubmit={handleSubmit}
        >
          <Typo $type="H2">Odzyskiwanie hasła</Typo>

          <Spacer $size="S" />

          <InputPESEL
            disabled={isLoading}
            // feedbackInfo="Wpisz swój numer PESEL powiązany z Twoim kontem"
            label="Podaj swój PESEL"
            onChange={(value) => onChangeFieldValue('pesel', value)}
            required
            type="pesel"
            value={values.pesel as string}
          />

          <Spacer $size="L" />

          <GoogleInfo />

          <Spacer $size="L" />

          <ButtonsWrapper>
            <Button disabled={isLoading} type="submit">Przejdź dalej</Button>
            {/* <NaviLink to="/">Wróc do logowania</NaviLink> */}
          </ButtonsWrapper>
        </LeftColumn>
        <RightColumn>
          <RevertTransform>
            <AboutWrapper>
              <AboutTypoRow1>
                <AboutTypo $type="H1">To nie koniec świata</AboutTypo>
              </AboutTypoRow1>
              <AboutTypoRow2>
                <AboutTypo $type="H1">Każdemu może się zdarzyć</AboutTypo>
              </AboutTypoRow2>
            </AboutWrapper>
          </RevertTransform>
        </RightColumn>
        <ToastContainer containerId="login" />
      </MainContent>
      <Footer />
    </>
  );
};
