import { useCallback, useEffect } from 'react';
import { apiPrefix } from '../../api';
import { FetchError, useFetch } from '../../hooks/useFetch';

interface Hook {
  tokenLoading: boolean;
  isReset?: boolean;
  tokenError: FetchError;
}

export function useToken(token: string): Hook {
  const [
    registerToken, , registerLoading, registerError,
  ] = useFetch();
  const [
    initToken, isReset, initLoading, initError,
  ] = useFetch<{ reset: boolean }, boolean>();

  const checkToken = useCallback(async () => {
    await initToken(`${apiPrefix}/onboarding-auth/public/register/${token}/init`, undefined, ({ reset }) => !!reset);
    registerToken(`${apiPrefix}/onboarding-auth/public/register/${token}`);
  }, [token, initToken, registerToken]);

  useEffect(() => {
    void checkToken();
  }, [checkToken]);

  return {
    tokenLoading: registerLoading || initLoading,
    isReset,
    tokenError: registerError || initError,
  };
}
