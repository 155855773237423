import { color } from '@nnpl/ui-library';
import { Logo } from '@nnpl/ui-library/esm/Logo';
import { Link } from '@nnpl/ui-library/esm/Link';
import styled from 'styled-components';
import { Typo } from '@k2nn/k2-ui-library';
import { PaddingsWrapper } from '../Margins/Margins';
import { Spacer } from '../Margins/Spacer';
import arrowSrc from './arrow.png';

const FooterWrapper = styled(PaddingsWrapper.withComponent('footer'))`
  background: ${color.primary.orangeLight};
  display: flex;
  position: sticky;
  bottom: 0;
`;

const FooterLeftColumn = styled.div`
  display: flex;
  width: 350px;
  flex-direction: column;
`;

const FooterRightColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
`;

const WhiteLink = styled(Link)`
  span { color: white; }
  font-weight: bold;
  white-space: nowrap;
`;

const SiteLink = styled(Link)`
  span { color: white; }
  margin-top: 10px;
  margin-bottom: 20px;

  &:hover { text-decoration: none; }
`;

const ArrowIcon = styled.img`
  margin-right: 16px;
  vertical-align: middle;
`;

// const NoWrap = styled.span`
//   white-space: nowrap;
// `;

export const Footer: React.FC = () => (
  <FooterWrapper $paddingsHorizontal="L" $paddingsVertical="XL">
    <FooterLeftColumn>
      <Logo variant="flatWhite">
        Logo
      </Logo>
      <Spacer $size="M" />
      <Typo $color="white" $type="caption">© 2021 Nationale-Nederlanden. Wszelkie prawa zastrzeżone.</Typo>
    </FooterLeftColumn>
    <FooterRightColumn>
      <SiteLink href="https://nn.pl">
        <ArrowIcon alt="arrow" src={arrowSrc} />
        nn.pl
      </SiteLink>
      <Typo $color="white" $type="H6">
        Masz problem z logowaniem? Napisz:&nbsp;&nbsp;&nbsp;
        <WhiteLink href="mailto:nnakademia@nn.pl">nnakademia@nn.pl</WhiteLink>
        {/* &nbsp;&nbsp; */}
        {/* <span style={{ color: '#EA650D' }}>&bull;</span> */}
        {/* <NoWrap> czynny pn. - pt., godz. 8-17</NoWrap> */}
      </Typo>
    </FooterRightColumn>
  </FooterWrapper>
);
