import { useCallback, useState } from 'react';

interface Hook<T> {
  values: T;
  errors: Record<keyof T, string>;
  onChangeFieldValue: (fieldName: keyof T, fieldValue: unknown) => void;
  onChangeFieldValidation: (fieldName: keyof T, error: string) => void;
  onResetForm: (values: T) => void;
}

export function useForm<T>(initialValues: T): Hook<T> {
  const [values, setValues] = useState<Hook<T>['values']>(initialValues);
  const [errors, setErrors] = useState<Hook<T>['errors']>({} as unknown as any);

  const onChangeFieldValue = useCallback<Hook<T>['onChangeFieldValue']>((fieldName, fieldValue) => {
    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: fieldValue,
    }));
  }, []);

  const onChangeFieldValidation = useCallback<Hook<T>['onChangeFieldValidation']>((fieldName, fieldError) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: fieldError,
    }));
  }, []);

  const onResetForm = useCallback<Hook<T>['onResetForm']>((resetValues) => {
    setValues(resetValues);
  }, [setValues]);

  return {
    values,
    errors,
    onChangeFieldValue,
    onChangeFieldValidation,
    onResetForm,
  };
}
