import { nnColors } from '@k2nn/k2-ui-library';
import { Button } from '@nnpl/ui-library/esm/Button';
import { ToastContainer, ToastVariant } from '@nnpl/ui-library/esm/Toast';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { useToastK2 } from '../../../hooks/useToastK2';
import { AboutWrapper, AboutTypo, AboutTypoRow } from '../../ui/About/AboutTypo';
import { Footer } from '../../ui/Footer/Footer';
import { InputPassword } from '../../ui/Form/InputPassword';
import { InputPasswordRepeat } from '../../ui/Form/InputPasswordRepeat';
import { Header } from '../../ui/Header/Header';
import { MainContent } from '../../ui/MainCotnent/MainContent';
import { PaddingsWrapper } from '../../ui/Margins/Margins';
import { Spacer } from '../../ui/Margins/Spacer';
import { Typo } from '../../ui/Typography/Typography';
import backgroundUrl from '../background.jpg';
import { usePasswordResetNew } from './usePasswordResetNew';

const AboutTypoRow1 = styled(AboutTypoRow)`
  width: 50%;
  min-width: 450px;
`;

const AboutTypoRow2 = styled(AboutTypoRow)`
  width: 94%;
  min-width: 450px;
  max-width: 1000px;
  color: ${nnColors.orangeDark};
`;

const LeftColumn = styled(PaddingsWrapper.withComponent('form'))`
  display: flex;
  flex-direction: column;
  width: 605px;
  background: ${nnColors.white};
  padding-top: 100px;
  justify-content: center;
`;

const RightColumn = styled.div`
  flex: 1;
  background: url(${backgroundUrl});
  background-size: cover;
  position: relative;
  transform: scaleX(-1);
`;

const RevertTransform = styled.div`
  transform: scaleX(-1);
  flex: 1;
  width: 100%;
  height: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  token: string;
}

export const PasswordResetNew: React.FC<Props> = ({ token }) => {
  const {
    handleSubmit, isLoading, values, onChangeFieldValue, successFirstName,
  } = usePasswordResetNew(token);
  const { playToast } = useToastK2();

  useEffect(() => {
    if (successFirstName) {
      playToast({
        message: 'Hasło zostało poprawnie zmienione. Zaloguj się za pomocą nowego hasła.',
        containerId: 'global',
        variant: ToastVariant.SUCCESS,
      });
    }
  }, [successFirstName, playToast]);

  if (successFirstName) {
    return (<Redirect to="/" />);
  }

  return (
    <>
      <Header />
      <MainContent>
        <LeftColumn
          $paddingBottom="XL"
          $paddingsVertical="XL"
          onSubmit={handleSubmit}
        >
          <Typo $type="H2">Odzyskiwanie hasła</Typo>

          <Spacer $size="S" />

          <InputPassword
            disabled={isLoading}
            label="Nadaj hasło"
            onChange={(value) => onChangeFieldValue('newPassword', value)}
            required
            type="password"
            value={values.newPassword}
          />

          <Spacer $size="M" />

          <InputPasswordRepeat
            basePassword={values.newPassword}
            disabled={isLoading}
            label="Powtórz hasło"
            onChange={(value) => onChangeFieldValue('newPasswordRepeat', value)}
            required
            type="password"
            value={values.newPasswordRepeat}
          />

          <Spacer $size="L" />

          <ButtonsWrapper>
            <Button disabled={isLoading} type="submit">Zmień hasło</Button>
            {/* <NaviLink to="/">Wróć do logowania</NaviLink> */}
          </ButtonsWrapper>
        </LeftColumn>
        <RightColumn>
          <RevertTransform>
            <AboutWrapper>
              <AboutTypoRow1>
                <AboutTypo $type="H1">To nie koniec świata</AboutTypo>
              </AboutTypoRow1>
              <AboutTypoRow2>
                <AboutTypo $type="H1">Każdemu może się zdarzyć</AboutTypo>
              </AboutTypoRow2>
            </AboutWrapper>
          </RevertTransform>
        </RightColumn>
        <ToastContainer containerId="login" />
      </MainContent>
      <Footer />
    </>
  );
};
