import styled, { css } from 'styled-components';
import { space, SpaceSizes } from './Margins';

interface Props {
  $size: SpaceSizes;
}

export const Spacer = styled.div`
  display: inline-block;
  ${({ $size }: Props) => css`
    width: ${space[$size]};
    min-width: ${space[$size]};
    max-width: ${space[$size]};
    height: ${space[$size]};
    min-height: ${space[$size]};
    max-height: ${space[$size]};
  `}
`;
