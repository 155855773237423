import styled from 'styled-components';

export const MainContent = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  background: white;
  min-height: 100vh;
  z-index: 1;
  position: relative;
`;
