import styled from 'styled-components';
import { ButtonTo } from '../../ui/ButtonTo/ButtonTo';
import { Footer } from '../../ui/Footer/Footer';
import { Header } from '../../ui/Header/Header';
import { MainContent } from '../../ui/MainCotnent/MainContent';
import { PaddingsWrapper } from '../../ui/Margins/Margins';
import { Spacer } from '../../ui/Margins/Spacer';
import { Typo } from '../../ui/Typography/Typography';
import personSrc from './person.png';

const RightColumn = styled(PaddingsWrapper.withComponent('div'))`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-right: 72px;
`;

const TextWrapper = styled.div`
  max-width: 545px;
`;

export const PasswordResetPESELSuccess: React.VFC = () => (
  <>
    <Header />
    <MainContent>
      <LeftColumn>
        <img alt="person" src={personSrc} />
      </LeftColumn>
      <RightColumn $paddingsHorizontal="XL">
        <TextWrapper>
          <Typo $color="orangeMedium" $type="H1">Sprawdź skrzynkę e-mail</Typo>
          <Typo $type="H5">
            Na Twój adres e-mail
            wysłaliśmy wiadomość z dalszymi krokami.
          </Typo>
          <Spacer $size="M" />
          <Typo $type="H5">
            Jeśli po kolejnych próbach nadal nie otrzymujesz
            odpowiedniej wiadomości skontaktuj się ze swoim menedżerem.
          </Typo>
          <Spacer $size="L" />
          <div>
            <ButtonTo to="/">Wróć do strony logowania</ButtonTo>
          </div>
        </TextWrapper>
      </RightColumn>
    </MainContent>
    <Footer />
  </>
);
