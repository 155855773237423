export interface Pagination {
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  totalElements: number;
  totalPages: number;
  size: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: { // duplicated?
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    },
    unpaged: boolean;
  },
  sort: { // duplicated?
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  },
}

export interface ErrorK2 {
  errors: {
    field: string;
    rejectedValue: string;
    messageKey: string; messageParams:null
  }[];
  correlationId:string;
  timestamp:string
}

export function isErrorK2(error?: unknown): error is ErrorK2 {
  if (!error || typeof error !== 'object' || !('errors' in error!)) {
    return false;
  }
  return !!error;
}
